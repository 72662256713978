import cx from 'classnames';
import React, { forwardRef } from 'react';
import { LinkProps as RouterLinkProps } from 'react-router-dom';

import * as styles from './styles.css';
import { ForwardRefComponent } from '../../../Types';
import Box, { BoxProps } from '../Box';

export enum SIZES {
  SMALL,
  MEDIUM,
}

type MergeProps<T, U> = Omit<T, keyof U> & U;

export interface LinkProps extends MergeProps<RouterLinkProps, Omit<BoxProps, 'ref'>> {
  disabled?: boolean;
  size?: SIZES;
  muted?: boolean;
  inherit?: boolean;
}

const Link: ForwardRefComponent<LinkProps, HTMLLinkElement> = forwardRef(
  ({ element, className, onClick, href, to, disabled, size = SIZES.MEDIUM, muted, ...rest }, ref) => {
    const el = element || (href ? 'a' : 'button');
    const isDisabled = (!href && !onClick && !to) || disabled;

    return (
      <Box
        ref={ref}
        element={el}
        className={cx(className, styles['link'], {
          [styles['link--disabled']]: isDisabled,
          [styles['link--small']]: size === SIZES.SMALL,
          [styles['link--muted']]: muted,
        })}
        href={href}
        onClick={!disabled ? onClick : undefined}
        to={to}
        disabled={el === 'button' ? isDisabled : undefined}
        {...rest}
      />
    );
  },
);

export default Link;
