import 'regenerator-runtime/runtime';

import { Primitive } from '@sentry/types';
import { ProviderConfig, withLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { FEATURE_FLAGS } from './Components/Context/Hooks/useFeatureFlag';
import { init as initAnalytics } from './Utils/analytics';
import { decodeUrl, TYPES, UrlContext } from './Utils/jwt';
import { init as initSentry } from './Utils/sentry';

const data = decodeUrl();

initSentry(data as Record<string, Primitive>);

if (window.analytics) {
  initAnalytics(data.type);
} else {
  window.addEventListener('TL.customerPortal.analytics.loaded', () => {
    initAnalytics(data.type);
  });
}

const flags: Record<string, any> = {};
Object.values(FEATURE_FLAGS).forEach((flag) => (flags[flag] = null));

type GenericWithLDProvider = (
  config: ProviderConfig,
) => <P = Record<string, unknown>>(component: React.ComponentType<P>) => React.ComponentType<P>;

const WrappedApp =
  !__DEV__ && data.accountId
    ? (withLDProvider as GenericWithLDProvider)({
        clientSideID: __CONFIG__.LAUNCH_DARKLY_KEY,
        context: {
          key: data.accountId,
        },
        options: {
          streaming: false,
        },
        flags,
      })(App)
    : App;

switch (data.type) {
  case TYPES.INVOICE:
    document.title = 'InvoiceCloud';
    break;

  case TYPES.DEAL:
    document.title = 'CloudSign';
    break;

  default:
    break;
}

const container = document.getElementById('root');

if (!container) {
  console.error('Root container not found');
} else {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <UrlContext.Provider value={data}>
        <WrappedApp data={data} />
      </UrlContext.Provider>
    </React.StrictMode>,
  );
}
