import React from 'react';
import ContentLoader from 'react-content-loader';

import Box, { BoxProps } from '../../../Components/UI/Box';
import { GenericComponent } from '../../../Types';

interface ContentLoaderBoxProps extends BoxProps {
  width: string | number;
  height: string | number;
  speed?: number;
  baseUrl?: string;
  viewBox?: string;
}

export const ANIMATION_SPEED = 1;
export const BACKGROUND_COLOR = '#E4E4E6';

const ContentLoaderBox: GenericComponent<ContentLoaderBoxProps> = ({ width, height, backgroundColor, ...rest }) => {
  return (
    <Box
      element={ContentLoader}
      backgroundColor={backgroundColor || BACKGROUND_COLOR}
      speed={ANIMATION_SPEED}
      baseUrl={window.location.href}
      viewBox={`0 0 ${width} ${height}`}
      height={height}
      {...rest}
    />
  );
};

export default ContentLoaderBox;
